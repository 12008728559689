import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import * as SVGIcon from '../../assets/svg'
import StackChart from "../../components/StackChart"
import commodityFunction from "../../hooks/commodityFunction"
import { useStoreSelector } from "../../store/useStore"
import priceFunction from "../../hooks/priceFunction"
import helpers from "../../utils/helpers"
const diamond: string = require('../../assets/icons/diamond.svg').default

export default () => {
   const { getMiningCount, getMiningGraph } = commodityFunction()
   const { getLicense } = priceFunction()
   const { miningListData, licenseData, miningGraphData } = useStoreSelector(["miningListData", "licenseData", "miningGraphData"])

   useEffect(() => {
      getMiningCount("", 'g')
      getLicense()
      getMiningGraph()
   }, [])
   let todayDate = new Date()
   // console.log(miningGraphData)
   // let arrayData = [
   //    { name: "Gold" },
   //    { name: "Tin" },
   //    { name: "Monazite sands" },
   //    { name: "Niobium" },
   //    { name: "Lithium" },
   //    { name: "Tantalite" },
   //    { name: "Zircon" },
   //    { name: "Gemstones" },
   // ]
   return (
      <AppLayout active="mining" title="Mining">
         <div className="container-fluid">
            <div className="row">
               {miningListData && miningListData.length > 0 ?
                  (miningListData || []).map((item: any, i: any) => (
                     <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div className="card h-auto newHt">
                           <div className="card-header pb-0 border-0 flex-wrap" style={{ alignItems: "flex-start" }}>
                              <div>
                                 <div className="chart-title mb-3">
                                 </div>
                              </div>
                              <div className="p-statics">
                                 <div className="d-flex align-items-center mb-3 ">
                                    <p className=""><span className="bgImg"><img src={diamond} /></span> {item?.name}</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card-body ptt-25 custome-tooltip pb-3" style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                              <div className="progress-content">
                                 <div className="justify-content-between mb-2">
                                    <h4 className="fontMen2">{helpers.formatMoney(item.total || 0)}/{item.unit.toLowerCase()}</h4>
                                    <h6 className="fontMen">Total for selected period</h6>
                                 </div>
                                 <div className="">
                                    {item?.status === 1 ?
                                       <div className="fillbg2">
                                          <h5 className="floatRight bBorder2">Exploration</h5>
                                       </div> : item?.status === 2 ? <div className="fillbg3">
                                          <h5 className="floatRight bBorder3">In production</h5>
                                       </div> : <div className="fillbg">
                                          <h5 className="floatRight bBorder">Pending</h5>
                                       </div>}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  )) : ""}

               <div className="col-12">
                  <div className="card h-auto">
                     <div className="card-body pt-2 custome-tooltip pb-3">
                        {/* <div className="table-responsive"> */}
                        <div id="activitys" style={{ paddingTop: "10px" }}>
                           <StackChart data={miningGraphData} />
                           <div className="card-header nheaderPad3 gotoTop">
                              {/* <h4 className="card-title ffh2">Mining states and commodities</h4> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-12">
                  <div className="card">
                     <div className="card-header nheaderPad">
                        <h6 className="heading card-title ffh">License Status</h6>
                     </div>
                     <div className="card-body" style={{ padding: "0" }}>
                        <div className="table-responsive ">
                           <table className="table table-bordered table-responsive-sm bgBrand">
                              <thead>
                                 <tr>
                                    <th>Asset Type</th>
                                    <th>Location</th>
                                    <th>Company</th>
                                    <th>Expiry Date</th>
                                    <th>Licence Number</th>
                                    <th>Licence Type</th>
                                    <th>Area (km<sup>2</sup>)</th>
                                    <th>Status</th>
                                 </tr>
                              </thead>
                              <tbody>

                                 {(licenseData || []).map((item: any, i: any) => (
                                    <tr>
                                       <th>{item?.commodity_id}</th>
                                       <td>{item?.location}</td>
                                       <td>{item?.company_data?.name}</td>
                                       <td>{new Date(item.expiration_date).toDateString()}</td>
                                       <td>{item?.number}</td>
                                       <td>{item?.type}</td>
                                       <td>{item?.cadastral}</td>
                                       <td><span className={`badge light ${todayDate < new Date(item.expiration_date) ? "badge-success" : "badge-danger"} `}>{todayDate < new Date(item.expiration_date) ? "Active" : "Expired"}</span></td>
                                    </tr>))}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </AppLayout>
   )
}